import gql from "graphql-tag";

export const PENDING_SURVEY_RESULTS = gql`
  query employee($id: ID!) {
    employee(id: $id) {
      pendingSurveyResults {
        id
        createdDateTime
        publishedSurvey {
          survey {
            id
            name
            color
          }
          endDateTime
          publishDateTime
        }
      }
    }
  }
`;
